import React from "react";
import App from "next/app";
import Head from "next/head";
import * as Sentry from "@sentry/browser";
import Footer from "../components/Footer";
import { AuthProvider } from "../auth";

import "../styles/main.css";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
});

class MyApp extends App {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <>
        <Head>
          <title>Margins.online</title>
          <link rel="shortcut icon" href="/static/favicon.ico" />
        </Head>
        <div className="max-w-xl mx-auto">
          <div className="min-h-screen">
            <AuthProvider>
              <Component {...pageProps} />
            </AuthProvider>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default MyApp;
