function Footer(): React.ReactNode {
  return (
    <footer className="flex justify-between my-6">
      <div>margins.online</div>
      <div className="cursor-pointer">
        <a onClick={(): void => window.scrollTo(0, 0)}>Back to top</a>
      </div>
      <div>© 2020</div>
    </footer>
  );
}

export default Footer;
